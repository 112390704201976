<template>
  <div class="container-Resgate">
        <!-- -->
      <section class="container-fluid">
        <section class="container" >
          <div class="row">
            <div class="col" id="topo-resgate"><br>
            <h1 style="color:#400041;" class="bold">Resgate de senha</h1><br>
            </div>
            </div>
        </section>

        <div class="row">

          <div class="col-sm-12 fundo-roxo quadro">
            <br>
            <div class="espacamento txt-white" >
              
              
              <div class="form-group d-block mx-auto" style="max-width:400px;">
                    <label class="bold">Insira seu e-mail abaixo:</label>
                    <input type="text" class="form-control" v-model="email" name="email" placeholder="E-mail" aria-describedby="email" required>
                    <small class="form-text text-muted"></small>
                </div>

                <button v-if="showLoading == false" style="max-width:400px;border:2px solid #FFF !important;" @click.prevent="enviar" type="button " class="btn bt-roxo btn-primary bold d-block mx-auto">RESGATAR SENHA</button>
            

            <div v-if="showLoading" class="d-flex justify-content-center">
        <p class="">Estamos enviando sua senha! Aguarde... </p>
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>


            </div>
                 


               
          </div><!-- col -->

         

        </div>
      </section>



      <div class="modal" id="modal-email" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Informações</h5>
        <button  type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Enviamos sua senha ao e-mail informado! Caso não o encontre alguarde alguns minutos e verifi-que sua caixa de spam. Obrigado.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
        
      </div>
    </div>
  </div>
</div>


      <!-- -->
  </div>
</template>

<style>

.espacamento{padding:0 40px;}
.fundo-laranja{background:#F18800;padding-bottom:20px;}
.fundo-roxo{background:#400041;padding-bottom:20px;}
.fundo-roxo2{background:#764573;padding-bottom:20px;}
.quadro{min-height:300px;}
.txt-white{color:#FFF;}
</style>

<script>
import servidor from '@/servidor'

export default {
  name: 'Resgate',
  components: {},
  data(){
    return {
     email:"",
     showLoading:false
    }
  },
  methods:{
   enviar(){
      this.showLoading = true;
      let dados = new FormData();
      dados.append('email',this.email);

     fetch(servidor+'email/resgatar',{method:"POST", body:dados})
          .then((response) => response.json())
          .then((data) => {
            this.showLoading = false;
             console.log(data);
             this.abrirModal();
            
          });

   },
    abrirModal(){
    window.jQuery('#modal-email').modal('show');
   }
  },
  computed:{
    
  },
  
  mounted(){
     this.$scrollTo("#topo-resgate");

  }
}
</script>